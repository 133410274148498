import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { rem } from 'styled-tidy'
import siteConfig from 'config/site'
import media from 'lib/styles/media'
import Layout from 'layouts/default'
import Gallery, { GalleryWrap } from 'components/gallery'
import Wrap from 'components/wrap'
import Title from 'components/title'
import Button from 'components/button'
import ButtonBar from 'components/button-bar'
import Paragraph from 'components/paragraph'
import Instagram from 'components/icons/instagram'

const MainWrap = styled(Wrap)`
  :last-child {
    padding-bottom: ${rem(40)};
  }

  div > div:nth-child(n + 5) {
    display: none;
  }

  ${media.small`
    div > div:nth-child(4) {
      display: none;
    }
  `}
  
  ${media.medium`
    div > div:nth-child(4) {
      display: block;
    }
  `}

  ${media.large`
    div > div:nth-child(5) {
      display: block;
    }
  `}
`

const CommissionsPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        images: allFile(filter: { sourceInstanceName: { eq: "images" } }) {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 400, fit: COVER) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `,
  )
  const imageNodes = data.images.edges
    .filter(edge => edge.node.relativePath.match(/gallery\/portraits\//i))
    .map(({ node }) => ({
      path: node.relativePath,
      fluid: node.childImageSharp.fluid,
    }))
    .sort((a, b) => (a.modifiedTime > b.modifiedTime ? -1 : 1))
    .slice(0, 6)

  return (
    <Layout path="/commissions">
      <MainWrap>
        <Title is="h1">Become Evilneon</Title>
        <Paragraph>
          Get your portrait imortalized with a custom Evilneon edit. Check out
          some of our recent edits:
        </Paragraph>
      </MainWrap>
      <GalleryWrap>
        <Gallery columns={3} imageNodes={imageNodes} />
      </GalleryWrap>
      <MainWrap>
        <ButtonBar>
          <Button
            is="a"
            kind="secondary"
            href={siteConfig.social.instagram.url}
            external
          >
            <Instagram size={16} />
            Inquiries
          </Button>
          <Button is="a" kind="secondary" href="/gallery">
            View More
          </Button>
        </ButtonBar>
      </MainWrap>
    </Layout>
  )
}

export default CommissionsPage
